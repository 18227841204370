/* eslint-disable no-restricted-globals */

let ws = new WebSocket(
    `${location.protocol === "http:" ? "ws" : "wss"}://` +
    location.host +
    "/api/ws"
);

ws.onclose = (e) => {
    console.error('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
    setTimeout(function () {
        initWS();
    }, 1000);
}

function initWS() {
    if (ws.readyState === WebSocket.CLOSED) {
        ws = new WebSocket(
            `${location.protocol === "http:" ? "ws" : "wss"}://` +
            location.host +
            "/api/ws"
        );

        ws.onclose = (e) => {
            console.error('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function () {
                initWS();
            }, 1000);
        }
    }
}

initWS();



export default ws;