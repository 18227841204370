import 'chart.js/auto';
import axios from "axios";
import { useEffect, useState } from "react";
import { Stats } from "../../models/stats";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import { Doughnut, Line } from 'react-chartjs-2';
import { dateOnly } from "../../services/date.service";
import "./home.scss";
import { Link } from "react-router-dom";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

export default function Home() {

    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [stats, setStats] = useState<Stats.RootObject>();
    const { state, setState } = useGlobalState();

    useEffect(() => {
        async function getStats() {
            try {
                let stats = (
                    await axios.get<Stats.RootObject>(
                        "/api/stats"
                    )
                ).data;
                setIsLoaded(true);
                setStats(stats);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading stats",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }

        getStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //chart stuff
    let allocatedChart: any = undefined;
    let deliveriesChart: any = undefined;
    let shippedChart: any = undefined;
    let baseColors = {
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1
    }
    if (stats) {
        allocatedChart = {
            labels: [`Unallocated`, `Allocated`],
            datasets: [
                {
                    label: "Stock History",
                    data: [(stats.allocated.q ?? 0) - (stats.allocated.qa ?? 0), stats.allocated.qa ?? 1],
                    fill: false,
                    ...baseColors
                }
            ],
        };
        deliveriesChart = {
            labels: stats.deliveries.map(x => dateOnly(new Date(x.date))),
            datasets: [
                {
                    label: "Delivery History",
                    data: stats.deliveries.map(x => +x.value),
                    fill: false,
                    backgroundColor: "#4b9ab0",
                    borderColor: "#6fd3ed",
                    tension: 0.3
                },
            ],
        };
        shippedChart = {
            labels: stats.shipped.map(x => dateOnly(new Date(x.date))),
            datasets: [
                {
                    label: "Shipped History",
                    data: stats.shipped.map(x => +x.value),
                    fill: false,
                    backgroundColor: "#d529b2",
                    borderColor: "#a7009a",
                    tension: 0.3
                },
            ],
        };

    }

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
    };

    const lineOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            mode: 'nearest',
            intersect: true
        },
        plugins: {
            title: {
                display: false
            },
            legend: {
                display: false
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            }
        },
    };

    function round(num: number) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Inventory</h2>
                </div>
                <div className="homeStats">
                    <div>
                        <div>
                            <h3>Current Stock Allocation</h3>
                            <div className="doughnutPercent">
                                {((stats?.allocated.qa ?? 1) / (stats?.allocated.q ?? 1) * 100).toFixed(2)}%
                            </div>
                            {allocatedChart ? <Doughnut data={allocatedChart} options={options} /> : ''}
                            {/* <Doughnut data={allocatedChart} options={options} /> */}
                        </div>
                    </div>
                    <div>
                        <div style={{ flex: 1 }}>
                            <h3>Deliveries By Day</h3>
                            {deliveriesChart ? <Line data={deliveriesChart} options={lineOptions} /> : ''}
                        </div>
                        <div style={{ flex: 1 }}>
                            <h3>Shipped Stock By Day</h3>
                            {shippedChart ? <Line data={shippedChart} options={lineOptions} /> : ''}
                        </div>
                    </div>
                    <div>
                        <div style={{ overflow: "auto" }}>
                            <h3>Low Stock Items</h3>
                            <p>
                                {stats?.lowStock.rows}/{stats?.itemTotal} items are low on stock <small>(Less than 50% of float value or no stock)</small>
                            </p>
                            <ul className="lowStockItems">
                                {stats?.lowStock.data.map(item => (
                                    <li key={item.id}>
                                        <Link
                                            to={{
                                                pathname: `/item/${item.id}`
                                            }}
                                        >
                                            {item.sku}
                                        </Link> &nbsp;
                                        <span>
                                            (<span className={item.stock <= 0 ? "negative" : "warning"}>
                                                {item.stock}
                                            </span>)

                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div style={{ overflow: "auto", flexGrow: 1 }}>
                            <h3>Unfullfilled Items</h3>
                            <p>
                                {stats?.notAllocated.length}/{stats?.itemTotal} items are not fully allocated
                            </p>
                            <TableContainer>
                                <Table aria-label="table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                SKU
                                            </TableCell>
                                            <TableCell>
                                                Required
                                            </TableCell>
                                            <TableCell>
                                                Allocated
                                            </TableCell>
                                            <TableCell>
                                                Available
                                            </TableCell>
                                            <TableCell>
                                                On Order
                                            </TableCell>
                                            <TableCell>
                                                Stock
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stats?.notAllocated.map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Link
                                                        to={{
                                                            pathname: `/item/${item.id}`
                                                        }}
                                                    >
                                                        {item.sku}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {round((item.qa - item.q) * -1)}
                                                </TableCell>
                                                <TableCell>
                                                    {round(item.qa)}/{round(item.q)}
                                                </TableCell>
                                                <TableCell>
                                                    <span className={(item.qa - item.q) * -1 > item.avaliable ? "negative" : "warning"}>
                                                        {round(item.avaliable)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {round(item.onOrder ?? 0)}
                                                </TableCell>
                                                <TableCell>
                                                    {round(item.stock)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}