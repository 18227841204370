/* eslint-disable no-restricted-globals */
import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IPurchaseOrder, IPurchaseOrderItem } from "../../models/order";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { toastType } from "../common/toast/toast";
import { Loader } from "../common/loader/loader";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Paper,
    Tabs,
    Tab,
    InputAdornment,
} from "@material-ui/core";
import "./orderDetail.scss";
import { supplier } from "../../models/supplier";

import { Slate, Editable, withReact } from "slate-react";

import { BaseEditor, createEditor } from "slate";
import { ReactEditor } from "slate-react";

import isHotkey from "is-hotkey";
import {
    BlockButton,
    deserialize,
    Element,
    HOTKEYS,
    Leaf,
    MarkButton,
    serialize,
    toggleMark,
} from "../common/slateHelpers";
import { PurchaseOrderTemplate } from "./purchaseOrderTemplate";
import { jsPDF } from "jspdf";
import { Confirmation } from "../common/confirmationDialog/confirmation";
import { HistoryTable } from "../shared/history/history";
import { dateStandard } from "../../services/date.service";
declare module "slate" {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor;
        Element: any;
        Text: any;
    }
}

export function OrderPage() {
    let { orderId } = useParams<{ orderId: string }>();

    const doc = new jsPDF("p", "px", [820, 1122], true);

    const [loading, setLoading] = useState(false);
    const [pdfExists, setPdfExists] = useState(false);
    const [emailSending, setEmailSending] = useState(false);
    const [error, setError] = useState<any>(false);
    const [emailSubject, setEmailSubject] = useState(
        "Purchase Order | Chocablock Chocolates"
    );
    const { state, setState } = useGlobalState();
    const [order, setOrder] = useState<IPurchaseOrder | null>(null);

    const [openDelete, setOpenDelete] = useState(false);

    const [supplier, setSupplier] = useState<supplier | null>(null);

    const [eValue, eSetValue] = useState<any[]>([]);
    const editor = useMemo(() => withReact(createEditor()), []);

    const renderElement = useCallback(
        (props: any) => <Element {...props} />,
        []
    );
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

    useEffect(() => {
        async function getPurchaseOrders() {
            let tmp = `<p>Hello {supplier|contact},<br><br>You have received a purchase order PO-{orderId} from Chocablock Chocolates.<br><br>Kind Regards,<br>{operatorName}<br>Chocablock Chocolates <br>Factory 15, 489a Warrigal Rd <br>Moorabbin VIC 3189 <br>03 9039 5350</p>`;

            const document = new DOMParser().parseFromString(tmp, "text/html");
            eSetValue(deserialize(document.body));
            setLoading(true);
            try {
                let tmpOrders: IPurchaseOrder = (
                    await axios.get<IPurchaseOrder>("/api/orders/" + orderId)
                ).data;
                if (!tmpOrders.note) tmpOrders.note = "-";
                if (!tmpOrders.gst) tmpOrders.gst = 0;
                setLoading(false);
                setOrder(tmpOrders);
                getHistory(tmpOrders);
            } catch (error) {
                setLoading(false);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading inventory",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }

        async function getHistory(tmpOrder: IPurchaseOrder) {
            try {
                setSupplier(
                    (
                        await axios.get<supplier>(
                            `/api/supplier/${tmpOrder?.supplier.id}`
                        )
                    ).data
                );
                setLoading(false);
            } catch (error) {
                console.error(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error getting item history",
                        type: toastType.Error,
                    })
                );
                setLoading(false);
            }
        }

        getPurchaseOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.purchaseOrder) updateOrder(info.purchaseOrder);
                if (info.orderDeleted) orderDeleted(info.orderDeleted);
            } catch (error) { }
        }

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const orderDeleted = (orderId: string) => {
        if (parseInt(orderId) === order?.id) {
            RouterHistory("/orders");
            setState(
                AddToToast(state, {
                    title: "Warning",
                    description: "Order was deleted",
                    type: toastType.Warning,
                })
            );
        }
    };

    function updateOrder(newOrder: IPurchaseOrder) {
        if (+(order?.sent ?? 1) === 0 && +newOrder.sent === 1 && value === 2) {
            setValue(0);
        }
        if (newOrder.id === order?.id) {
            setOrder(newOrder);
        }
    }

    async function submit(values: any) {
        try {
            await axios.put(`/api/order/update/${order?.id}`, values);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Updated Purchase Order",
                    type: toastType.Success,
                })
            );
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Update Purchase Order",
                    type: toastType.Error,
                })
            );
        }
    }

    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const formValidation = Yup.object().shape({
        supplier: Yup.object().required("Required"),
        items: Yup.array().of(
            Yup.object().shape({
                itemId: Yup.number().required("Item is Required"),
                to_deliver: Yup.number()
                    .required("Must have an amount to be delivered")
                    .typeError("Must be a number")
                    .min(1, "You should order more than 0"),
                delivered: Yup.number()
                    .required("Required")
                    .typeError("Must be a number"),
                cost: Yup.number()
                    .required("Item must have a cost")
                    .typeError("Cost must be a number")
                    .min(1, "Cost should be greater than 0"),
            })
        ),
        gst: Yup.boolean(),
        note: Yup.string(),
    });

    async function sendEmail() {
        let content = "";
        for (const val of eValue) {
            content += serialize(val);
        }
        setEmailSending(true);
        let pdf = await pdfData();
        try {
            await axios.post(`/api/orders/send`, {
                content: content,
                subject: emailSubject,
                orderId: order?.id,
                pdf: pdf,
            });
            setValue(0);
            setState(
                AddToToast(state, {
                    title: "Sent",
                    description: "Email was sent",
                    type: toastType.Info,
                })
            );
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "There was an error sending the email",
                    type: toastType.Error,
                })
            );
        }
        setEmailSending(false);
    }

    function getPDF() {
        if (pdfExists && window.open) {
            window.open(`/assets/pdfs/${order?.id}.pdf`, "_blank")!.focus();
        } else {
            let el = document.getElementById("pdfOrder");
            if (!el) return;
            // TODO: Make work with dark theme
            doc.html(el, {
                callback: (doc) => {
                    doc.output("dataurlnewwindow");
                },
                x: 10,
                y: 10,
            });
        }
    }

    function pdfData() {
        return new Promise<string>((resolve, reject) => {
            let el = document.getElementById("pdfOrder");
            if (!el) return;
            // TODO: Make work with dark theme
            doc.html(el, {
                callback: (doc) => {
                    resolve(doc.output("datauristring"));
                },
                x: 10,
                y: 10,
            });
        });
    }

    function checkPdf() {
        let iFrame: any = document.getElementById("purchaseOrderPdf");
        if (iFrame?.contentDocument?.contentType === "text/html") {
            setPdfExists(false);
        } else {
            setPdfExists(true);
        }
    }

    const deleteInfo = {
        header: "Delete Purchase Order",
        text: "Are you sure you want to delete this order, it cannot be undone",
        confirmText: "Delete",
        cancelText: "Cancel",
        closeFunction: deleteOrder,
    };

    let RouterHistory = useNavigate();

    async function deleteOrder(canDelete = false) {
        setOpenDelete(false);
        if (canDelete && order) {
            try {
                await axios.delete(`/api/order/${order.id}`);
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Order Deleted",
                        type: toastType.Success,
                    })
                );
                RouterHistory("/orders");
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "Order wasn't Deleted",
                        type: toastType.Error,
                    })
                );
            }
        }
    }

    if (loading || !order)
        return (
            <div className="card">
                <Loader />
            </div>
        );
    if (error) return <div className="card">Error: {error?.message}</div>;
    return (
        <div className="card">
            <div className="itemHeader">
                <h2>
                    Purchase Order #{orderId} ({order?.supplier.name})
                </h2>
            </div>
            <br />
            <Paper>
                <Tabs centered value={value} onChange={handleChange}>
                    <Tab label="Details" />
                    <Tab label="History" />
                    <Tab label="Purchase Order" />
                    {!order?.sent ? <Tab label="Email" /> : ""}
                </Tabs>
            </Paper>
            <br />
            <div hidden={value !== 0}>
                <p>
                    <b>Created: </b> {dateStandard(new Date(order?.created ))}
                </p>
                <p>
                    Supplier Email:{" "}
                    <a href={"mailto:" + supplier?.email}>{supplier?.email}</a>
                    <br />
                    Supplier Phone:{" "}
                    <a href={"tel:" + supplier?.phone}>{supplier?.phone}</a>
                </p>
                <br />
                <Formik
                    initialValues={order}
                    enableReinitialize
                    onSubmit={async (values: any, actions) => {
                        await submit(values);
                        actions.setSubmitting(false);
                    }}
                    validationSchema={formValidation}
                >
                    {(props: any) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldValue,
                        } = props;

                        function deliverAll() {
                            let itemCpy = [
                                ...(values.items as IPurchaseOrderItem[]),
                            ];
                            for (const item of itemCpy) {
                                item.delivered = item.to_deliver;
                            }
                            setFieldValue("items", itemCpy);
                        }

                        return (
                            <form
                                className="detailForm"
                                onSubmit={handleSubmit}
                            >
                                <div>
                                    <TextField
                                        value={values.supplier.name}
                                        label="Supplier"
                                        disabled
                                    />
                                    <TextField
                                        name="note"
                                        value={values.note}
                                        onChange={handleChange}
                                        helperText={
                                            errors.note &&
                                            touched.note &&
                                            errors.note
                                        }
                                        error={!!errors.note && !!touched.note}
                                        disabled={!!values.fulfilled}
                                        label="Optional Note"
                                        type="text"
                                    />
                                    {/* <TextField
                                        name="gst"
                                        value={values.gst}
                                        onChange={handleChange}
                                        helperText={
                                            errors.gst &&
                                            touched.gst &&
                                            errors.gst
                                        }
                                        error={!!errors.gst && !!touched.gst}
                                        label="GST"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        }}
                                    /> */}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="gst"
                                                checked={!!values.gst}
                                                disabled={!!values.fulfilled}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="GST"
                                    />
                                </div>
                                <div>
                                    <p>Items:</p>
                                    <hr />
                                    {(values.items.every(
                                        (x: IPurchaseOrderItem) => x.delivered === x.to_deliver
                                    ) || !!values.fulfilled) ? (
                                        ""
                                    ) : (
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            onClick={deliverAll}
                                        >
                                            All Recieved
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    {values.items.map(
                                        (
                                            item: IPurchaseOrderItem,
                                            i: number
                                        ) => (
                                            <div
                                                key={item.itemId}
                                                className="newItem"
                                            >
                                                <span style={{ "flexBasis": "17%" }} title={item.description ?? ""}>{item.sku}</span>
                                                <TextField
                                                    onBlur={handleBlur}
                                                    margin="dense"
                                                    value={
                                                        values.items[i].delivered / item.unit_quantity
                                                    }
                                                    name={`item${i}delivered`}
                                                    onChange={(e) => {
                                                        let val = +e.target.value * item.unit_quantity;
                                                        if (val > item.to_deliver) {
                                                            setFieldValue(`items[${i}].delivered`, item.to_deliver);
                                                        } else {
                                                            setFieldValue(`items[${i}].delivered`, val);
                                                        }
                                                    }}
                                                    helperText={
                                                        errors.items?.[i]
                                                            ?.delivered &&
                                                        touched.items?.[i]
                                                            ?.delivered &&
                                                        errors.items?.[i]
                                                            ?.delivered
                                                    }
                                                    error={
                                                        !!errors.items?.[i]
                                                            ?.delivered
                                                    }
                                                    disabled={!!values.fulfilled}
                                                    label={item.duof + " Delivered"}
                                                    type="text"
                                                />
                                                <TextField
                                                    onBlur={handleBlur}
                                                    margin="dense"
                                                    name={`item${i}to_deliver`}
                                                    value={
                                                        values.items[i].to_deliver / item.unit_quantity
                                                    }
                                                    disabled={!!order.sent || !!values.fulfilled}
                                                    onChange={(e) => {
                                                        setFieldValue(`items[${i}].to_deliver`, +e.target.value * item.unit_quantity);
                                                        setFieldValue(`items[${i}].cost`, +e.target.value * (values.items[i].cost / (values.items[i].to_deliver / item.unit_quantity)));
                                                    }}
                                                    helperText={
                                                        errors.items?.[i]
                                                            ?.to_deliver &&
                                                        touched.items?.[i]
                                                            ?.to_deliver &&
                                                        errors.items?.[i]
                                                            ?.to_deliver
                                                    }
                                                    error={
                                                        !!errors.items?.[i]
                                                            ?.to_deliver
                                                    }
                                                    label={item.duof + " To Deliver"}
                                                    type="number"
                                                />
                                                <TextField
                                                    onBlur={handleBlur}
                                                    margin="dense"
                                                    name={`items[${i}].cost`}
                                                    value={values.items[i].cost}
                                                    onChange={handleChange}
                                                    helperText={
                                                        errors.items?.[i]
                                                            ?.cost &&
                                                        touched.items?.[i]
                                                            ?.cost &&
                                                        errors.items?.[i]?.cost
                                                    }
                                                    error={
                                                        !!errors.items?.[i]
                                                            ?.cost
                                                    }
                                                    disabled={!!values.fulfilled}
                                                    label="Total Price"
                                                    type="text"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    onBlur={handleBlur}
                                                    margin="dense"
                                                    disabled
                                                    value={(
                                                        values.items[i].cost /
                                                        values.items[i]
                                                            .to_deliver
                                                    ).toFixed(2)}
                                                    label="Unit Price"
                                                    type="text"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    onBlur={handleBlur}
                                                    margin="dense"
                                                    disabled
                                                    value={(
                                                        values.items[i].cost / (values.items[i].to_deliver / item.unit_quantity)
                                                    ).toFixed(2)}
                                                    label={item.duof + " Price"}
                                                    type="text"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    onBlur={handleBlur}
                                                    margin="dense"
                                                    disabled
                                                    value={`${item.delivered}/${item.to_deliver}`}
                                                    label={"Units"}
                                                    type="text"
                                                />
                                            </div>
                                        )
                                    )}
                                    <hr />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="fulfilled"
                                                checked={!!values.fulfilled}
                                                disabled={!!order.fulfilled}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="Fulfilled - Close Purchase Order"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="sent"
                                                checked={!!values.sent}
                                                disabled
                                            />
                                        }
                                        label="Email Sent"
                                    />
                                </div>
                                {!!order.fulfilled ? "" : <div>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                </div>}
                            </form>
                        );
                    }}
                </Formik>
            </div>
            <div hidden={value !== 1}>
                <HistoryTable
                    id={order.id}
                    location="order"
                    eventName="orderHistory"
                    perPage={25}
                    ref={undefined}
                />
            </div>
            <div hidden={value !== 3}>
                <TextField
                    label="Subject"
                    defaultValue={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    variant="outlined"
                    fullWidth
                />
                <br />
                <br />
                <Slate
                    editor={editor}
                    value={eValue}
                    onChange={(newValue) => eSetValue(newValue)}
                >
                    <Paper>
                        <MarkButton format="bold" icon="format_bold" />
                        <MarkButton format="italic" icon="format_italic" />
                        <MarkButton
                            format="underline"
                            icon="format_underlined"
                        />
                        <MarkButton format="code" icon="code" />
                        <BlockButton format="heading-one" icon="looks_one" />
                        <BlockButton format="heading-two" icon="looks_two" />
                        <BlockButton format="block-quote" icon="format_quote" />
                        <BlockButton
                            format="numbered-list"
                            icon="format_list_numbered"
                        />
                        <BlockButton
                            format="bulleted-list"
                            icon="format_list_bulleted"
                        />
                    </Paper>
                    <br />
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder="Enter some rich text…"
                        spellCheck
                        autoFocus
                        style={{
                            border: "1px solid rgba(255, 255, 255, 0.23)",
                            borderRadius: "4px",
                            padding: "10px",
                        }}
                        onKeyDown={(event) => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event as any)) {
                                    event.preventDefault();
                                    const mark = HOTKEYS[hotkey];
                                    toggleMark(editor, mark);
                                }
                            }
                        }}
                    />
                </Slate>
                <br />
                <div style={{ width: "100%" }}>
                    {/* <p>Supported fields</p>
                    <ul>
                        <li>
                            {"{supplier|contact}"}: The supplier name and or the
                            allocated contact name for the supplier
                        </li>
                        <li>
                            {"{orderId}"}: Purchase order number padded to 4
                            digits (0012)
                        </li>
                        <li>
                            {"{operatorName}"}: The name of the user that sends
                            the email
                        </li>
                    </ul> */}
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={sendEmail}
                        disabled={emailSending}
                    >
                        {emailSending ? "Sending" : "Send"}
                    </Button>
                </div>
            </div>
            <div className={`${value !== 2 ? "orderHidden" : ""}`}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => getPDF()}
                >
                    View PDF
                </Button>
                <div hidden={!pdfExists}>
                    <iframe
                        id="purchaseOrderPdf"
                        onLoad={checkPdf}
                        title="pruchaseOrderpdf"
                        src={`/assets/pdfs/${order.id}.pdf`}
                    ></iframe>
                </div>
                <div id="pdfOrder" hidden={pdfExists}>
                    <PurchaseOrderTemplate order={order} />
                </div>
            </div>
            <br />
            <br />
            <Button
                variant="outlined"
                className="dangerBtn"
                onClick={() => setOpenDelete(true)}
            >
                Delete
            </Button>
            <Confirmation info={deleteInfo} isOpen={openDelete} />
        </div>
    );
}
