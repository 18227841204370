import React from "react";
import "./Sidebar.scss";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

export class Sidebar extends React.Component<{
    navOpen: boolean;
    theme: string;
    toggleTheme: Function;
}> {
    click = () => {
        this.props.toggleTheme();
        this.curTheme = this.curTheme === "dark" ? "light" : "dark";
        this.setThemeBtn();
    };

    curTheme = this.props.theme;
    themeButton = (
        <span onClick={this.click} className="fa material-icons">
            dark_mode
        </span>
    );

    setThemeBtn() {
        if (this.curTheme === "dark") {
            this.themeButton = (
                <span onClick={this.click} className="fa material-icons">
                    light_mode
                </span>
            );
        } else {
            this.themeButton = (
                <span onClick={this.click} className="fa material-icons">
                    dark_mode
                </span>
            );
        }
    }

    render() {
        this.setThemeBtn();
        return (
            <div
                className={`sidebar ${!this.props.navOpen ? "collapsed" : ""}`}
            >
                <nav className="sideNav">
                    <ul className="accordion">
                        <NavLink end to="/" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">home</i>
                                    Home
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/inventory" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        inventory_2
                                    </i>
                                    Inventory
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/orders" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        request_page
                                    </i>
                                    Purchase Orders
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/recipes" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        menu_book
                                    </i>
                                    Recipes
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/items" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        category
                                    </i>
                                    Items
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/wastage" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        delete
                                    </i>
                                    Wastage
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/charlie" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        store
                                    </i>
                                    Orders
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/suppliers" className={({ isActive }) => isActive ? "navActive": ""}>
                            <li>
                                <span className="infoHeader">
                                    <i className="fa material-icons">
                                        local_shipping
                                    </i>
                                    Suppliers
                                </span>
                            </li>
                        </NavLink>
                    </ul>
                    <div className="navFooter">
                        <Tooltip title="Toggle light/dark theme">{this.themeButton}</Tooltip>
                    </div>
                </nav>
            </div>
        );
    }
}
