import { TextField } from "@material-ui/core";

export function SearchBar(props: { filterFunction: Function }) {
    return (
        <TextField
            label="Search"
            InputProps={{
                startAdornment: (
                    <span className="material-icons">
                        search
                    </span>
                ),
            }}
            onChange={(e) => props.filterFunction(e.target.value)}
            fullWidth
        />
    )
}