import "./toast.scss";
import React, { useState, useEffect } from "react";
import { ToastItem } from "./toastItem";

export const Toast = (props: { toastList: toastItem[]; position: string }) => {
    let { position, toastList } = props;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList(toastList);
    }, [toastList, list]);

    const deleteToast = (id: number) => {
        const index = list.findIndex(e => e.id === id);
        list.splice(index, 1);
        setList([...list]);
    }

    return (
        <div className={`notification-container ${position}`}>
            {list.map((toast: any, i: number) => (
                <ToastItem toast={toast} key={i} removeItem={deleteToast} />
            ))}
        </div>
    );
};

export interface toastItem {
    id: number;
    type: toastType;
    title: string;
    description: string;
    backgroundColor: string;
    icon: string;
}

export enum toastType {
    Success,
    Info,
    Warning,
    Error,
}
