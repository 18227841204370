// import classes from "*.module.css";
import {
    TableCell,
    TableRow,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { item } from "../../models/items";
import "./item.scss";

export function ItemRow(props: { row: item; openModal: Function }) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow className={row.stock < 0 ? "stockWarning" : ""}>
                <TableCell component="th" scope="row">
                    <Link
                        to={{
                            pathname: `/item/${row.id}`
                        }}
                    >
                        {row.sku}
                    </Link>
                </TableCell>
                <TableCell component="th">{row.description ?? "-"}</TableCell>
                <TableCell align="right">
                    <span
                        className={
                            row.stock > row.float
                                ? "positive"
                                : row.stock > 0.2 * row.float
                                    ? "warning"
                                    : "negative"
                        }
                    >
                        {row.stock}
                    </span>
                </TableCell>
                {/* <TableCell align="right">{row.limit ?? "-"}</TableCell>
                <TableCell align="right">{row.float}</TableCell>
                <TableCell align="right">{row.order_quantity}</TableCell> */}
            </TableRow>
        </React.Fragment>
    );
}
