import { detailQuery, inventoryNew } from "../../models/inventory";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import React from "react";
import axios from "axios";
import { AddToToast } from "../../services/toast.service";
import { useGlobalState } from "../../services/auth.service";
import { toastType } from "../common/toast/toast";
import { Loader } from "../common/loader/loader";
import { dateStandard } from "../../services/date.service";
import { Link } from "react-router-dom";
import { numToDecimal } from "../../services/generic.helper";

export function InventoryRow(props: { row: inventoryNew, openModal: Function }) {
    const [open, setOpen] = React.useState(false);
    const { row } = props;

    const [detailRows, setDetailRows] = React.useState<detailQuery[]>([]);
    const [loading, setLoading] = React.useState(false);
    const { state, setState } = useGlobalState();

    async function openSection() {
        setOpen(!open);
        if (!open) {
            setLoading(true);
            try {
                let detailReq = await axios.get<detailQuery[]>(`/api/inventory/detail/${row.id}`);
                detailReq.data.sort((a, b) => {
                    return (new Date(b.dispatchDate)).valueOf() - (new Date(a.dispatchDate)).valueOf()
                });
                setDetailRows(detailReq.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error getting item history",
                        type: toastType.Error,
                    })
                );
                setLoading(false);
            }
        }
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={openSection}
                    >
                        {open ? (
                            <span className="material-icons">expand_less</span>
                        ) : (
                            <span className="material-icons">expand_more</span>
                        )}
                    </IconButton>
                </TableCell>
                <TableCell scope="row">
                    <Link
                        to={{
                            pathname: `/item/${row.id}`
                        }}
                    >
                        {row.sku}
                    </Link>
                </TableCell>
                <TableCell>
                    {row.description ?? "-"}
                </TableCell>
                <TableCell align="right">{row.stock}</TableCell>
                <TableCell align="right">
                    <span
                        className={
                            row.stock - (row.qa ?? 0) <= 0
                                ? "negative"
                                : row.stock - (row.qa ?? 0) <=
                                    row.float
                                    ? "warning"
                                    : "positive"
                        }
                    >
                        {row.stock - (row.qa ?? 0)}
                    </span>
                </TableCell>
                <TableCell align="right">
                    {numToDecimal(row.qa ?? 0)}/{numToDecimal(row.q ?? 0)}
                </TableCell>
                <TableCell align="right">
                    {/* <Tooltip title="Create Purchase Order"> */}
                        <IconButton title="Create Purchase Order" color="secondary" size="small" onClick={() => props.openModal(row)}>
                            <span className="material-icons">
                                add_shopping_cart
                            </span>
                        </IconButton>
                    {/* </Tooltip> */}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <h3>Included Orders</h3>
                            <Table size="small" className="detailTableBody">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>SKU</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Dispatch</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Loader />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        detailRows.map((detailRow) => (
                                            <TableRow
                                                key={
                                                    detailRow.orderId
                                                }
                                            >
                                                <TableCell>
                                                    <Link
                                                        to={{
                                                            pathname: `/charlie/${detailRow.orderId}`
                                                        }}
                                                    >
                                                        #{detailRow.orderNumber}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {detailRow.sku}
                                                </TableCell>
                                                <TableCell>
                                                    {detailRow.qty}
                                                </TableCell>
                                                <TableCell>
                                                    {dateStandard(
                                                        new Date(
                                                            detailRow.dispatchDate
                                                        )
                                                    ) ?? "-"}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}
