import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { item } from "../../models/items";
import { useGlobalState } from "../../services/auth.service";
import { dateOnly } from "../../services/date.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import { Link } from "react-router-dom";
import "./wastage.scss";
import { Wastage } from "../../models/wastage";
import { BasicUser } from "../../models/auth";
import { SearchBar } from "../common/search/search";

interface newWastage {
    item: item | null;
    quantity: number;
    reason: string;
    user?: string;
}

export function WastagePage() {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(false);
    const [search, setSearch] = useState("");
    const [staff, setStaff] = useState<BasicUser[]>([]);
    const [formValid, setFormValid] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [wastage, setWastage] = useState<Wastage[]>([]);
    const [items, setItems] = useState<item[]>([]);
    const [newWastage, setNewWastage] = useState<newWastage[]>([{
        item: null,
        quantity: 0,
        reason: "",
        user: undefined
    }]);
    const { state, setState } = useGlobalState();
    let autocompleteRender = 1;

    const [page, setPage] = useState(0);
    const [fetchedPages, setFetchedPages] = React.useState<number[]>([]);
    const [tableRows, setTableRows] = useState(0);

    useEffect(() => {
        async function getItems() {
            setLoading(true);
            try {
                let tmpUsers = (
                    await axios.get<BasicUser[]>("/api/users/all")
                ).data;
                tmpUsers.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setStaff(tmpUsers);
                let tmpOrder: item[] = (
                    await axios.get<item[]>("/api/items/all")
                ).data;
                tmpOrder.sort((a, b) => {
                    if (a.sku < b.sku) return -1;
                    if (a.sku > b.sku) return 1;
                    return 0;
                });
                let tmpWastage = (
                    await axios.get<{
                        rows: number;
                        data: Wastage[];
                    }>("/api/wastage")
                ).data;
                setLoading(false);
                setItems(tmpOrder);
                setWastage(tmpWastage.data);
                setTableRows(tmpWastage.rows);
            } catch (error) {
                setLoading(false);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading the items",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }

        getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.wastageUpdate) {
                    setWastage([info.wastageUpdate, ...wastage]);
                }
            } catch (error) { }
        }

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wastage]);

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            try {
                // setLoading(true);
                setFetchedPages([0]);
                setPage(0);

                let tmpWastage = (
                    await axios.get<{
                        rows: number;
                        data: Wastage[];
                    }>(`/api/wastage?search=${search}`)
                ).data;
                setLoading(false);
                setWastage(tmpWastage.data);
                setTableRows(tmpWastage.rows);
            } catch (error) {
                setLoading(false);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading wastage",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    async function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        if (!fetchedPages.includes(newPage)) {
            setLoading(true);
            let wastageReq = await axios.get<{
                rows: number;
                data: Wastage[];
            }>(`/api/wastage?page=${newPage}`);
            let newWastage = [...wastage, ...wastageReq.data.data];
            setFetchedPages([...fetchedPages, newPage]);
            setWastage(newWastage);
            setTableRows(wastageReq.data.rows);
            setLoading(false);
        }
        setPage(newPage);
    }

    function handleChange<T extends keyof newWastage>(property: T, id: number, value: newWastage[T]) {
        let tmpNewWastage = [...newWastage];
        if (tmpNewWastage[id]) {
            tmpNewWastage[id][property] = value;
            if (tmpNewWastage[id].item && property === "item") {
                tmpNewWastage.push({
                    item: null,
                    quantity: 0,
                    reason: "",
                    user: undefined
                });
            }
            setNewWastage(tmpNewWastage);
        }
        checkForm();
    }

    function checkForm() {
        let tmpFormValid = newWastage.filter(x => x.item).length > 0;
        newWastage.filter(x => x.item).forEach((wastage) => {
            if (!wastage.item || !wastage.quantity || !wastage.reason) {
                tmpFormValid = false;
            }
            if (wastage.reason === "Broken" && !wastage.user) {
                tmpFormValid = false;
            }
        });
        setFormValid(tmpFormValid);
    }

    async function submit() {
        setSubmitting(true);
        try {
            await axios.post("/api/wastage/new", newWastage);
            setNewWastage([{
                item: null,
                quantity: 0,
                reason: "",
                user: undefined
            }]);
            setState(
                AddToToast(state, {
                    title: "Added Successfully",
                    description: "The wastage has been added successfully",
                    type: toastType.Success,
                })
            );
        } catch (error) {
            setLoading(false);
            setError(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "There was an error submitting the wastage",
                    type: toastType.Error,
                })
            );
            console.error(error);
        }
        setSubmitting(false);
    }

    const filterSearch = async (searchVal: string) => {
        setSearch(searchVal);
    };


    if (loading) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    }
    if (error) return <div className="card">Error: {error?.message}</div>;
    return (
        <div className="card">
            <div className="itemHeader">
                <h2>Wastage</h2>
            </div>
            <hr />
            <h3>Add wastage</h3>
            <div className="addWastage">
                {newWastage.map((x, i) => {
                    return (
                        <div className="line" key={i}>
                            <Autocomplete
                                key={autocompleteRender}
                                options={items}
                                getOptionSelected={(option, value) => option.id === value.id ?? ""}
                                getOptionLabel={(option: Required<{ id: number; sku: string }> & { description?: string }) =>
                                    option.sku + ' - ' + option.description
                                }
                                openOnFocus
                                onChange={(event, val) => handleChange("item", i, val as item)}
                                value={x.item}
                                style={{ width: 300 }}
                                renderInput={(params: any) => (
                                    <TextField {...params} label="SKU" />
                                )}
                            />
                            {
                                !x.item ? '' : (
                                    <React.Fragment>
                                        <FormControl style={{ width: 300 }}>
                                            <InputLabel>Reason</InputLabel>
                                            <Select
                                                label="Reason"
                                                value={x.reason}
                                                onChange={(event) => handleChange('reason', i, (event.target.value as string))}
                                            >
                                                <MenuItem value={'Broken'}>Broken</MenuItem>
                                                <MenuItem value={'Unsealed'}>Unsealed</MenuItem>
                                                <MenuItem value={'Imperfections'}>Imperfections</MenuItem>
                                                <MenuItem value={'Out Of Date'}>Out Of Date</MenuItem>
                                                <MenuItem value={'Samples'}>Samples issued by director</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            name="quantity"
                                            value={x.quantity}
                                            onChange={(event) => handleChange('quantity', i, parseFloat(event.target.value))}
                                            label="Quantity"
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                    </React.Fragment>
                                )
                            }
                            {
                                x.reason !== 'Broken' ? '' : (
                                    <FormControl style={{ width: 300 }}>
                                        <InputLabel>Cause</InputLabel>
                                        <Select
                                            label="Cause"
                                            value={x.user ? x.user : ""}
                                            onChange={(event) => handleChange('user', i, (event.target.value as string))}
                                        >
                                            <MenuItem value={'Supplier'}>Supplier</MenuItem>
                                            {staff.map(x => (
                                                <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            }
                        </div>
                    )
                })}
                <br />
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!formValid && !isSubmitting}
                    type="submit"
                    className="submitBtn"
                    onClick={() => submit()}
                >
                    Submit
                </Button>
            </div>
            <br />
            <hr />
            <br />
            <SearchBar filterFunction={filterSearch} />
            <br />
            <br />
            <TableContainer>
                <Table aria-label="collapsible table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Reason</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wastage
                            .map((row, i) => (
                                <TableRow
                                    key={i}
                                >
                                    <TableCell>
                                        <Link
                                            to={{
                                                pathname: `/item/${row.item.itemId}`,
                                            }}
                                        >
                                            {row.item.sku ?? "-"}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.quantity ?? "-"}</TableCell>
                                    <TableCell>{row.reason ?? "-"}</TableCell>
                                    <TableCell>{row.user ?? "-"}</TableCell>
                                    <TableCell>{dateOnly(new Date(row.date))}</TableCell>
                                </TableRow>
                            ))
                            .slice(page * 50, (page + 1) * 50)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[50]}
                                colSpan={5}
                                count={tableRows ?? 0}
                                rowsPerPage={50}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    )
}