import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from "@material-ui/core";
import { useState } from "react";
import "./App.scss";
import { Router } from "./components/router/router";
import { authCon, GlobalStateProvider } from "./services/auth.service";

function App() {
    const [theme, setThemeActive] = useState(
        localStorage.getItem("theme") ?? "light"
    );

    (async () => {
        await authCon.getUser();
    })();

    const matTheme = createMuiTheme({
        palette: {
            primary: {
                light: "#91f2ff",
                main: "#5bbfde",
                dark: "#158fac",
                contrastText: "#000000",
            },
            secondary: {
                light: "#ff66e5",
                main: "#d529b3",
                dark: "#a00083",
                contrastText: "#fff",
            },
            type: theme === "dark" ? "dark" : "light",
        },
    });

    function setTheme() {
        let curTheme = localStorage.getItem("theme");
        if (!curTheme) {
            curTheme = "light";
            localStorage.setItem("theme", "light");
        }
        document.body.classList.add(theme);
    }

    setTheme();

    return (
        <ThemeProvider theme={matTheme}>
            <GlobalStateProvider>
                <Router theme={theme} setThemeActive={setThemeActive} />
            </GlobalStateProvider>
        </ThemeProvider>
    );
}

export default App;
