import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";
import { item } from "../../models/items";
import { useGlobalState } from "../../services/auth.service";
import { dateStandard } from "../../services/date.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import * as Yup from "yup";

export function EditItem(props: { item: item | null; closeDialog: Function }) {
    let { item, closeDialog } = props;

    let initValues = {
        ...{
            id: 0,
            sku: "",
            stock: 0,
            limit: null,
            description: "",
            float: 0,
            order_quantity: 0,
            loading: false,
        },
        ...item,
    };
    const [formState, setFormState] = useState(initValues);
    const { state, setState } = useGlobalState();

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true,
        });
        try {
            delete update.loading;
            if (update.limit === "") update.limit = null;
            let item = await axios.put(`/api/items/${formState.id}`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Updated Item",
                    type: toastType.Success,
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Update Item",
                    type: toastType.Error,
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        sku: Yup.string().required("Required"),
        stock: Yup.number().required("Required"),
        float: Yup.number().required("Required"),
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Update Item ({item?.sku})</DialogTitle>
            <Formik
                initialValues={initValues}
                onSubmit={(values: any) => handleClose(values)}
                validationSchema={formValidation}
            >
                {(props: any) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    <small>
                                        Last modified{" "}
                                        {dateStandard(
                                            new Date(item ? item.modified : "")
                                        )}
                                    </small>
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="sku"
                                    value={values.sku}
                                    onChange={handleChange}
                                    helperText={
                                        errors.sku && touched.sku && errors.sku
                                    }
                                    label="SKU"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="description"
                                    value={values.description ?? ""}
                                    onChange={handleChange}
                                    helperText={
                                        errors.description && touched.description && errors.description
                                    }
                                    label="Description"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    name="limit"
                                    value={values.limit ?? ""}
                                    onChange={handleChange}
                                    label="Stock Limit"
                                    helperText="Leave blank to remove limit"
                                    type="number"
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    name="float"
                                    value={values.float}
                                    onChange={handleChange}
                                    label="Stock Float"
                                    helperText="The amount of stock to keep on hand, setting to 0 will disable automatic orders"
                                    type="number"
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    name="order_quantity"
                                    value={values.order_quantity}
                                    onChange={handleChange}
                                    label="Order Quantity"
                                    helperText={
                                        errors.order_quantity &&
                                        touched.order_quantity &&
                                        errors.order_quantity
                                    }
                                    type="number"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => handleClose(false)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
