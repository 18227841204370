import { Button } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import "./header.scss";

export function Header(props: { navOpen: boolean; toggleBurger: Function }) {
    const { state, setState } = useGlobalState();
    const [titleText, setTitleText] = useState("Inventory System");

    async function logout() {
        try {
            await axios.get("/api/auth/logout");
            setState({ ...state, ...{ user: null } });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (window.innerWidth < 992) {
            setTitleText("Inventory");
        } else {
            setTitleText("Inventory System");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderUser() {
        if (state.user) {
            return (
                <div className="dropDown">
                    <div>
                        {state.user.first_name} {state.user.last_name}
                        <span className="material-icons">expand_more</span>
                    </div>
                    <div className="dropdown-content">
                        <Button size="small" color="secondary" onClick={logout}>
                            Log Out
                        </Button>
                    </div>
                </div>
            );
        } else {
            return "";
        }
    }

    const navOpen = props.navOpen;
    return (
        <div className="header">
            <div
                className={
                    "hamburger hamburger--spin js-hamburger" +
                    (navOpen && " is-active")
                }
                onClick={() => props.toggleBurger()}
            >
                <div className=" hamburger-box ">
                    <div className="hamburger-inner"></div>
                </div>
            </div>
            <h1>{titleText}</h1>
            {renderUser()}
        </div>
    );
}