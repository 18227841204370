import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Formik } from "formik";
import * as Yup from "yup";
import {
    purchaseOrder,
    purchaseOrderItemRequest,
    purchaseOrderRequest,
} from "../../models/order";
import { item } from "../../models/items";
import { supplier } from "../../models/supplier";
import { Autocomplete } from "@material-ui/lab";
import { inventory } from "../../models/inventory";

export function CreateOrder(props: {
    closeDialog: Function;
    item?: inventory;
}) {
    let { closeDialog } = props;

    let initValues: purchaseOrderRequest & { loading: boolean } = {
        supplierId: 0,
        items: [],
        note: "",
        loading: false,
    };

    if (props.item) {
        // initValues.items.push({
        //     itemId: props.item.id,
        //     to_deliver: props.item.order_quantity,
        //     delivered: 0,
        //     duof: props.item.du,
        //     unit_quantity: number,
        //     cost: 0,
        // });
    }

    const [formState, setFormState] = useState<
        Partial<purchaseOrder> & { loading: boolean }
    >({ ...initValues });
    const { state, setState } = useGlobalState();

    const [items, setItems] = useState<item[]>([]);
    const [suppliers, setSuppliers] = useState<supplier[]>([]);
    const [refresh, setRefresh] = useState(0);
    const [itemsCopy, setItemsCopy] = useState<item[]>([]);

    useEffect(() => {
        async function getExtraInfo() {
            try {
                let items: item[] = (await axios.get<item[]>("/api/items/all"))
                    .data;
                items.sort((a, b) => {
                    if (a.sku < b.sku) return -1;
                    if (a.sku > b.sku) return 1;
                    return 0;
                });
                setItemsCopy(items);
                setItems([]);
                let supp: supplier[] = (
                    await axios.get<supplier[]>("/api/suppliers/all")
                ).data;
                supp.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setRefresh(refresh + 1);
                setSuppliers(supp);
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading items",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }
        getExtraInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true,
        });
        try {
            delete update.loading;
            // update.items = update.items.map(x => )
            for (const item of update.items as purchaseOrderItemRequest[]) {
                item.to_deliver = item.to_deliver * item.unit_quantity;
            }
            console.log(update);
            let item = await axios.post(`/api/orders`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Created Purchase Order",
                    type: toastType.Success,
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Create Purchase Order",
                    type: toastType.Error,
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        supplierId: Yup.number().required("Required"),
        items: Yup.array()
            .min(1, "Must have at least 1 item")
            .of(
                Yup.object().shape({
                    itemId: Yup.number().required("Item is Required"),
                    to_deliver: Yup.number()
                        .required("Must have an amount to be delivered")
                        .typeError("Must be a number")
                        .min(1, "You should order more than 0"),
                    delivered: Yup.number().required("Required"),
                    cost: Yup.number()
                        .required("Item must have a cost")
                        .typeError("Cost must be a number")
                        .min(1, "Cost should be greater than 0"),
                })
            ),
        gst: Yup.number()
            .min(0, "GST cannot be less than 0")
            .typeError("Must be a number"),
        note: Yup.string(),
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Create Purchase Order</DialogTitle>
            <Formik
                initialValues={initValues}
                onSubmit={(values: any) => handleClose(values)}
                validationSchema={formValidation}
                validateOnChange
            >
                {(props: { values: typeof initValues, [key: string]: any }) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        handleBlur,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <Autocomplete
                                    options={suppliers}
                                    onBlur={handleBlur}
                                    getOptionSelected={(option, value: any) => {
                                        return option.id === value;
                                    }}
                                    getOptionLabel={(option: any) =>
                                        suppliers.find(
                                            (x) => x.id === option.id
                                        )?.name ??
                                        suppliers.find((x) => x.id === option)
                                            ?.name ??
                                        "-"
                                    }
                                    fullWidth
                                    value={values.supplierId as any}
                                    onChange={(_, value) => {
                                        if (!value) return;
                                        if (!value.id) {
                                            setItems(itemsCopy);
                                        } else {
                                            let supplierItems: number[] =
                                                value.items?.map(
                                                    (x: item) => x.id
                                                ) ?? [];
                                            setItems(
                                                itemsCopy.filter((x) =>
                                                    supplierItems.includes(x.id)
                                                )
                                            );
                                            setFieldValue(
                                                "supplierId",
                                                value?.id ?? 0
                                            );
                                        }
                                    }}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label="Supplier"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    onBlur={handleBlur}
                                    options={items.filter(
                                        (x) =>
                                            !values.items.find(
                                                (y: purchaseOrderItemRequest) =>
                                                    x.id === y.itemId
                                            )
                                    )}
                                    key={refresh}
                                    getOptionSelected={(option, value) => {
                                        return option.id === +value.id;
                                    }}
                                    getOptionLabel={(option: any) => {
                                        let item = items.find((x) => x.id === option.id) ?? items.find((x) => x.id === option.id);
                                        if (item) {
                                            return item.sku + " - " + (item.description ?? "");
                                        } else {
                                            return "-"
                                        }
                                    }}
                                    fullWidth
                                    onChange={(_, value) => {
                                        if (value) {
                                            setFieldValue("items", [
                                                ...(values.items ?? []),
                                                {
                                                    itemId: value?.id,
                                                    to_deliver:
                                                        value?.order_quantity ?? 0,
                                                    duof: value.duof,
                                                    unit_quantity: value.unit_quantity,
                                                    delivered: 0,
                                                    cost: 0,
                                                },
                                            ]);
                                            setRefresh(refresh + 1);
                                        }
                                    }}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label="Add Item"
                                        />
                                    )}
                                />
                                <br />
                                <p>Items:</p>
                                <hr />
                                {values.items.map(
                                    (
                                        item: purchaseOrderItemRequest,
                                        i: number
                                    ) => (
                                        <div
                                            key={item.itemId}
                                            className="newItem"
                                        >
                                            <span>
                                                {
                                                    items.find(
                                                        (x) =>
                                                            x.id === item.itemId
                                                    )?.sku
                                                }
                                            </span>
                                            <TextField
                                                onBlur={handleBlur}
                                                autoFocus
                                                margin="dense"
                                                name={`items[${i}].to_deliver`}
                                                value={
                                                    values.items[i].to_deliver
                                                }
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    let itemInfo = items.find(x => x.id === item.itemId);
                                                    setFieldValue(`items[${i}].cost`, Math.round(((+e.target.value * (itemInfo?.unit_quantity ?? 1)) * (itemInfo?.cost ?? 0) + Number.EPSILON) * 100) / 100);
                                                }}
                                                helperText={
                                                    errors.items?.[i]
                                                        ?.to_deliver &&
                                                    touched.items?.[i]
                                                        ?.to_deliver &&
                                                    errors.items?.[i]
                                                        ?.to_deliver
                                                }
                                                error={
                                                    !!errors.items?.[i]
                                                        ?.to_deliver
                                                }
                                                label={item.duof + " Quantity"}
                                                type="text"
                                            />
                                            <TextField
                                                onBlur={handleBlur}
                                                margin="dense"
                                                name={`items[${i}].cost`}
                                                value={values.items[i].cost}
                                                onChange={handleChange}
                                                helperText={
                                                    errors.items?.[i]?.cost &&
                                                    touched.items?.[i]?.cost &&
                                                    errors.items?.[i]?.cost
                                                }
                                                error={
                                                    !!errors.items?.[i]?.cost
                                                }
                                                label="Total Price"
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Tooltip title="Remove Item">
                                                <IconButton
                                                    aria-label="remove"
                                                    size="small"
                                                    className="dangerBtn"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            "items",
                                                            values.items.filter(
                                                                (
                                                                    x: any,
                                                                    index: number
                                                                ) => index !== i
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        cancel
                                                    </span>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                )}
                                <br />

                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="note"
                                    value={values.note}
                                    onChange={handleChange}
                                    helperText={
                                        errors.note &&
                                        touched.note &&
                                        errors.note
                                    }
                                    error={!!errors.note && !!touched.note}
                                    label="Optional Note"
                                    type="text"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => handleClose(false)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={
                                        isSubmitting ||
                                        Object.keys(touched).length === 0 ||
                                        Object.keys(errors).length > 0
                                    }
                                    type="submit"
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
