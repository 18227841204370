import axios from "axios";
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState,
} from "react";
import { userQuery } from "../models/auth";

export interface GlobalStateInterface {
    user: userQuery | null;
    toastList: any[],
    ws: WebSocket
}

const GlobalStateContext = createContext({
    state: {} as Partial<GlobalStateInterface>,
    setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

const GlobalStateProvider = ({
    children,
    value = {} as GlobalStateInterface,
}: {
    children: React.ReactNode;
    value?: Partial<GlobalStateInterface>;
}) => {
    const [state, setState] = useState(value);
    return (
        <GlobalStateContext.Provider value={{ state, setState }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error(
            "useGlobalState must be used within a GlobalStateContext"
        );
    }
    return context;
};

export { GlobalStateProvider, useGlobalState };

let curUser: userQuery | null = null;
export let checkedUser = false;

class AuthService extends React.Component<{}, { curUser: userQuery | null }> {
    private curRequest: Promise<any> | null = null;

    constructor(props: {} | Readonly<{}>) {
        super(props);
        // this.state = {
        //     curUser: null
        // }
        if (!curUser && !this.curRequest) {
            this.curRequest = this.updateUser();
        }
    }

    get user() {
        return curUser;
    }

    set user(newUser: userQuery | null) {
        // this.setState({
        //     curUser: newUser,
        // });
    }

    async updateUser() {
        this.curRequest = axios.get("/api/auth/@me");
        let req = await this.curRequest;
        if (req.data.id) {
            // this.state.curUser = req.data;
            // this.setState({
            //     curUser: req.data,
            // });
            curUser = req.data;
        } else {
            curUser = null;
        }
        checkedUser = true;
        this.curRequest = null;
        return curUser;
    }

    async getUser(): Promise<userQuery | null> {
        if (this.curRequest) {
            return await this.curRequest;
        } else {
            return curUser;
        }
    }
}

export const authCon = new AuthService({});
