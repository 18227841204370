import { toastItem, toastType } from "../components/common/toast/toast";

export function AddToToast(state: any, itemToAdd: Partial<toastItem>) {
    let newToast = [...(state.toastList ?? [])];
    itemToAdd = {
        ...itemToAdd,
        id: new Date().valueOf()
    }
    switch (itemToAdd.type) {
        case toastType.Success:
            itemToAdd = {
                ...itemToAdd,
                backgroundColor: "#28a745",
                icon: "done"
            }
            break;
        case toastType.Warning:
            itemToAdd = {
                ...itemToAdd,
                backgroundColor: "#f0ad4e",
                icon: "warning"
            }
            break;
        case toastType.Info:
            itemToAdd = {
                ...itemToAdd,
                backgroundColor: "#17a2b8",
                icon: "info"
            }
            break;
        case toastType.Error:
            itemToAdd = {
                ...itemToAdd,
                backgroundColor: "#dc3545",
                icon: "error"
            }
            break;
    
        default:
            break;
    }
    newToast.push(itemToAdd)
    return {
        ...state,
        ...{
            toastList: newToast
        }
    }
}