import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { purchaseOrder } from "../../models/order";
import { dateStandard } from "../../services/date.service";
import { Link } from "react-router-dom";
import "./orders.scss";
import { lerp } from "../../services/generic.helper";

export function OrderRow(props: { row: purchaseOrder; openModal: Function }) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <Link
                        to={{
                            pathname: `/orders/${row.id}`
                        }}
                    >
                        #{row.id}
                    </Link>
                </TableCell>
                <TableCell>{row.supplierName}</TableCell>
                <TableCell align="right">{row.skus}</TableCell>
                <TableCell align="right">${row.cost.toFixed(2)}</TableCell>
                <TableCell align="right">
                    {dateStandard(new Date(row.created))}
                </TableCell>
                <TableCell align="right">
                    <div className="progressBox">
                        <div className="boxText">
                            {row.delivered}/{row.to_deliver}
                        </div>
                        <div
                            className="boxBack"
                            style={{
                                width:
                                    (row.delivered / row.to_deliver) * 100 +
                                    "%",
                                backgroundColor: `hsl(${lerp(0,120,(row.delivered/row.to_deliver))}, 100%, 50%)`
                            }}
                        ></div>
                    </div>
                </TableCell>
                <TableCell align="right">
                    <Checkbox checked={!!row.sent} disabled />
                </TableCell>
                <TableCell align="right">
                    {dateStandard(new Date(row.fulfilled ?? "-"))}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
