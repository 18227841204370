import {
    Box,
    Collapse,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { supplier } from "../../models/supplier";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Confirmation } from "../common/confirmationDialog/confirmation";
import { toastType } from "../common/toast/toast";
import { HistoryTable } from "../shared/history/history";
import "./suppliers.scss";

export function SupplierRow(props: { row: supplier; openModal: Function }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const { state, setState } = useGlobalState();

    async function openSection() {
        setOpen(!open);
    }

    const deleteInfo = {
        header: "Delete Supplier",
        text: "Are you sure you want to delete this supplier, it cannot be undone",
        confirmText: "Delete",
        cancelText: "Cancel",
        closeFunction: deleteRecipe
    }

    async function deleteRecipe(canDelete = false) {
        setOpenDelete(false);
        if (canDelete && row) {
            try {
                await axios.delete(`/api/supplier/${row.id}`);
                setState(
                    AddToToast(state, {
                        title: "Success",
                        description: "Supplier Deleted",
                        type: toastType.Success,
                    })
                );
            } catch (error) {
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "Supplier wasn't Deleted",
                        type: toastType.Error,
                    })
                );
            }

        }
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={openSection}
                    >
                        {open ? (
                            <span className="material-icons">expand_less</span>
                        ) : (
                            <span className="material-icons">expand_more</span>
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.note}
                </TableCell>
                <TableCell align="right">
                    {row.email}
                </TableCell>
                <TableCell align="right" className="no-wrap">{row.phone}</TableCell>
                <TableCell align="right">
                    <Tooltip title="Edit">
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                props.openModal(row);
                                setOpen(false);
                            }}
                        >
                            <span className="material-icons">edit</span>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    <Tooltip title="Delete">
                        <IconButton
                            className="dangerBtn"
                            onClick={() => {
                                setOpenDelete(true);
                            }}
                        >
                            <span className="material-icons">delete</span>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <h3>History</h3>
                            <HistoryTable id={row.id} location="suppliers" ref={undefined} eventName="supplierHistory" />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <Confirmation info={deleteInfo} isOpen={openDelete} />
        </React.Fragment>
    );
}
