import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";

import { Formik } from "formik";
import * as Yup from "yup";
import { supplier } from "../../models/supplier";

export function AddSupplier(props: { closeDialog: Function }) {
    let { closeDialog } = props;

    let initValues: Partial<supplier> & { loading: boolean } = {
        id: 0,
        name: "",
        email: "",
        abn: "",
        phone: "",
        address: "",
        post_code: "",
        city: "",
        state: "",
        note: null,
        contact: null,
        loading: false,
    };
    const [formState, setFormState] = useState<Partial<supplier> & { loading: boolean }>({ ...initValues });
    const { state, setState } = useGlobalState();

    async function handleClose(update: any) {
        if (!update) return closeDialog(false);
        setFormState({
            ...formState,
            loading: true,
        });
        try {
            delete update.loading;
            let item = await axios.post(`/api/suppliers/new`, update);
            setState(
                AddToToast(state, {
                    title: "Success",
                    description: "Added Supplier",
                    type: toastType.Success,
                })
            );
            closeDialog(item.data);
        } catch (error) {
            console.error(error);
            setState(
                AddToToast(state, {
                    title: "Error",
                    description: "Couldn't Add Supplier",
                    type: toastType.Error,
                })
            );
            closeDialog(false);
        }
    }

    const formValidation = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
        abn: Yup.string(),
        phone: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        post_code: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        contact: Yup.string().nullable(),
        note: Yup.string().nullable()
    });

    return (
        <React.Fragment>
            {formState.loading ? <Loader onTop={true} /> : ""}
            <DialogTitle>Add Supplier</DialogTitle>
            <Formik
                initialValues={initValues}
                onSubmit={(values: any) => handleClose(values)}
                validationSchema={formValidation}
            >
                {(props: any) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        handleBlur
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <TextField
                                    onBlur={handleBlur}
                                    autoFocus
                                    margin="dense"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    helperText={
                                        errors.name && touched.name && errors.name
                                    }
                                    error={!!errors.name && !!touched.name}
                                    label="Name"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={
                                        errors.email && touched.email && errors.email
                                    }
                                    error={!!errors.email && !!touched.email}
                                    label="Email"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="abn"
                                    value={values.abn}
                                    onChange={handleChange}
                                    helperText={
                                        errors.abn && touched.abn && errors.abn
                                    }
                                    error={!!errors.abn && !!touched.abn}
                                    label="ABN"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    helperText={
                                        errors.phone && touched.phone && errors.phone
                                    }
                                    error={!!errors.phone && !!touched.phone}
                                    label="Phone"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    helperText={
                                        errors.address && touched.address && errors.address
                                    }
                                    error={!!errors.address && !!touched.address}
                                    label="Street Address"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    helperText={
                                        errors.city && touched.city && errors.city
                                    }
                                    error={!!errors.city && !!touched.city}
                                    label="City/Town"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    helperText={
                                        errors.state && touched.state && errors.state
                                    }
                                    error={!!errors.state && !!touched.state}
                                    label="State"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="post_code"
                                    value={values.post_code}
                                    onChange={handleChange}
                                    helperText={
                                        errors.post_code && touched.post_code && errors.post_code
                                    }
                                    error={!!errors.post_code && !!touched.post_code}
                                    label="Post Code"
                                    type="text"
                                    fullWidth
                                />
                                <br />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="contact"
                                    value={values.contact}
                                    onChange={handleChange}
                                    helperText={
                                        errors.contact && touched.contact && errors.contact
                                    }
                                    error={!!errors.contact && !!touched.contact}
                                    label="Contact Name"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    onBlur={handleBlur}
                                    margin="dense"
                                    name="note"
                                    value={values.note}
                                    onChange={handleChange}
                                    helperText={
                                        errors.note && touched.note && errors.note
                                    }
                                    error={!!errors.note && !!touched.note}
                                    label="Supplier Note"
                                    type="text"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => handleClose(false)}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isSubmitting || Object.keys(touched).length === 0 || Object.keys(errors).length > 0}
                                    type="submit"
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}