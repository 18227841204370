import { useEffect, useState } from "react";
import { Header } from "../header/header";
import { Page404 } from "../Page404/Page404";
import { Sidebar } from "../Sidebar/Sidebar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { authCon, useGlobalState } from "../../services/auth.service";
import { Login } from "../login/login";
import { Toast } from "../common/toast/toast";
import { ItemPage } from "../items/item";
import { SupplierPage } from "../suppliers/suppliers";
import { InventoryPage } from "../inventory/inventory";
import { PurchaseOrderPage } from "../purchaseOrders/orderPage";
import { OrderPage } from "../orderDetailPage/orderDetailPage";
import { RecipePage } from "../recipes/recipe";
import { RecipeDetail } from "../recipes/recipeDetail";
import { CharlieOrdersPage } from "../charlieOrder/charlieOrder";
import { CharlieDetailPage } from "../charlieOrder/charlieDetail";
import { ItemDetail } from "../items/itemDetail";
import ws from "../../services/ws.service";
import Home from "../home/home";
import { WastagePage } from "../wastage/wastage";

export function Router(props: { theme: any; setThemeActive: any }) {
    const [isActive, setActive] = useState(true);
    const { state, setState } = useGlobalState();

    const toggleClass = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        if (window.innerWidth < 992) {
            setActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if (!state.ws) {
                setState({
                    ...state, ...{
                        ws: ws,
                        user: await authCon.getUser(),
                    }
                });
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.ws, state.user]);


    function setTheme() {
        let curTheme = localStorage.getItem("theme");
        if (!curTheme) {
            curTheme = "light";
            localStorage.setItem("theme", "light");
        }
        document.body.classList.add(props.theme);
    }

    setTheme();

    function toggleTheme() {
        let curTheme = localStorage.getItem("theme");
        if (curTheme) {
            curTheme = props.theme === "dark" ? "light" : "dark";
            localStorage.setItem("theme", curTheme);
            props.setThemeActive(curTheme);
        }
        document.body.classList.toggle("dark");
        document.body.classList.toggle("light");
    }

    if (!state.user) {
        return (
            <div>
                <Login />
                <Toast toastList={state.toastList ?? []} position="bottom-right" />
            </div>
        )
    }

    return (
        <div>
            <Header navOpen={isActive} toggleBurger={toggleClass} />
            <div className="container">
                <BrowserRouter>
                    <Sidebar
                        navOpen={isActive}
                        theme={props.theme}
                        toggleTheme={toggleTheme}
                    />
                    <div className="main">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/inventory" element={<InventoryPage />} />
                            <Route path="/orders/:orderId" element={<OrderPage />} />
                            <Route path="/orders" element={<PurchaseOrderPage />} />
                            <Route path="/recipes" element={<RecipePage />} />
                            <Route path="/recipe/:recipeId" element={<RecipeDetail />} />
                            <Route path="/wastage" element={<WastagePage />} />
                            <Route path="/items" element={<ItemPage />} />
                            <Route path="/item/:itemId" element={<ItemDetail />} />
                            <Route path="/charlie" element={<CharlieOrdersPage />} />
                            <Route path="/charlie/:orderId" element={<CharlieDetailPage />} />
                            <Route path="/suppliers" element={<SupplierPage />} />
                            <Route path="*" element={<Page404 />} />
                        </Routes>
                    </div>
                </BrowserRouter>
            </div>
            <Toast toastList={state.toastList ?? []} position="bottom-right" />
        </div>
    );
}
