export function dateStandard(date: Date) {
    let options: any = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    try {
        return new Intl.DateTimeFormat("en-GB", options).format(date);
    } catch (error) {
        return "-";
    }
}

export function dateOnly(date: Date) {
    try {
        // return new Intl.DateTimeFormat("en-GB", options).format(date);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        return dd + '/' + mm + '/' + yyyy;
    } catch (error) {
        return "-";
    }
}
