/* eslint-disable no-restricted-globals */
import {
    Paper,
    Tabs,
    Tab,
    Checkbox,
    FormControlLabel,
    Button,
    TextField,
    Tooltip,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { charlieItemDetail, orderDetail } from "../../models/charlieOrders";
import { useGlobalState } from "../../services/auth.service";
import { dateOnly } from "../../services/date.service";
import { numToDecimal, titleCase } from "../../services/generic.helper";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import { HistoryTable } from "../shared/history/history";
import "./charlieOrders.scss";

export function CharlieDetailPage() {
    let { orderId } = useParams<{ orderId: string }>();

    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState<any>(false);
    const { state, setState } = useGlobalState();
    const [order, setOrder] = useState<orderDetail | null>(null);

    let editCopy: orderDetail | null = null;

    useEffect(() => {
        async function getPurchaseOrders() {
            setLoading(true);
            try {
                let tmpOrder: orderDetail = (
                    await axios.get<orderDetail>("/api/charlie/" + orderId)
                ).data;
                setLoading(false);
                setOrder(tmpOrder);
            } catch (error) {
                setLoading(false);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading the order",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }

        getPurchaseOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.orderUpdate) updateOrder(info.orderUpdate);
            } catch (error) { }
        }

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    function updateOrder(newOrder: orderDetail) {
        if (newOrder.orderId === order?.orderId) {
            setOrder(newOrder);
        }
    }

    async function finishEdit(save: boolean) {
        setEditMode(false);
        if (!save || (save && editCopy === order)) {
        } else {
            let promises = [];
            if (!order) return;
            for (const orderItem of order?.orderItems) {
                for (const item of orderItem.items) {
                    promises.push(
                        axios.put("/api/charlie/adjustStock", {
                            stockId: item.stockId,
                            assigned: item.assigned,
                        })
                    );
                }
            }
            Promise.allSettled(promises).then(([result]) => {
                setState(
                    AddToToast(state, {
                        title: "Updated",
                        description: "Items were updated",
                        type: toastType.Success,
                    })
                );
            });
        }
    }

    function adjustItem(
        newVal: number,
        item: charlieItemDetail,
        itemId: number
    ) {
        if (!order) return;
        let copy: orderDetail = { ...order };
        let updatedItem = copy?.orderItems
            ?.find((x) => x.itemId === itemId)
            ?.items.find((x) => x === item);
        if (updatedItem) {
            if (newVal > updatedItem.required) newVal = updatedItem.required;
            let diff = updatedItem.assigned - newVal;
            let avaliable = updatedItem.avaliable + diff;
            if (avaliable >= 0) {
                updatedItem.avaliable = avaliable;
                updatedItem.assigned = newVal;
                copy.qa -= diff;
            } else {
                // updatedItem.assigned = avaliable;
            }
            setOrder(copy);
        }
    }

    function toggleEditMode() {
        setEditMode(!editMode);
    }

    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    if (loading || !order)
        return (
            <div className="card">
                <Loader />
            </div>
        );
    if (error) return <div className="card">Error: {error?.message}</div>;
    return (
        <div className="card">
            <div className="itemHeader">
                <h2>
                    Order #{order.orderNumber} ({order.name})
                </h2>
            </div>
            <br />
            <span className={`badge-${order.orderStatus.replaceAll("_", "-")}`}>
                {titleCase(order.orderStatus.replaceAll("_", " ")) ?? "-"}
            </span>
            <span>
                {order.tags.map((tag) => (
                    <Tooltip title={tag.name} arrow>
                        <i
                            className="tag"
                            style={{ backgroundColor: tag.color }}
                        ></i>
                    </Tooltip>
                ))}
            </span>
            <br />
            <br />
            <p>
                <a
                    href={`https://charlie.chocablock.com.au/orders/detail/${order.orderId}`}
                    target="blank"
                >
                    View on Charlie
                </a>
            </p>
            <p>
                <b>Order Date: </b> {dateOnly(new Date(order.orderDate))}
            </p>
            <p>
                <b>Dispatch Date: </b> {dateOnly(new Date(order.dispatchDate))}
            </p>
            <p>
                <b>Allocated: </b>
                <span
                    className={
                        order.qa - order.q === 0 ? "positive" : "negative"
                    }
                >
                    {numToDecimal(order.qa)}/{numToDecimal(order.q)}
                </span>
            </p>
            <br />
            <Paper>
                <Tabs centered value={value} onChange={handleChange}>
                    <Tab label="Details" />
                    <Tab label="History" />
                </Tabs>
            </Paper>
            <br />
            <div hidden={value !== 0}>
                <h4>Order Items:</h4>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={editMode}
                            checked={editMode}
                            onChange={() => toggleEditMode()}
                        />
                    }
                    label={
                        <div style={{ display: "flex" }}>
                            <span className="material-icons">edit</span>Manually
                            Allocate Stock
                        </div>
                    }
                />
                {editMode ? (
                    <React.Fragment>
                        <Button
                            onClick={() => finishEdit(false)}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => finishEdit(true)}
                        >
                            Save
                        </Button>
                    </React.Fragment>
                ) : (
                    ""
                )}
                <br />
                <div className="items">
                    {order.orderItems.map((row) => (
                        <React.Fragment key={row.itemId}>
                            <div>
                                <div className="info">
                                    <p>
                                        <b>SKU: </b>
                                        {row.sku.toUpperCase()}
                                    </p>
                                    <p>
                                        <b>Quantity: </b> {row.quantity}
                                    </p>
                                </div>
                                <div className="subItems">
                                    {row.items.map((item) => (
                                        <div key={row.itemId + item.sku}>
                                            <p style={{ padding: "3px" }} title={item.description}>
                                                <b>{item.sku}</b>
                                            </p>
                                            <hr />
                                            <p>
                                                <b>Allocated: </b>
                                                <span
                                                    className={
                                                        item.assigned >=
                                                            item.required
                                                            ? "positive"
                                                            : "negative"
                                                    }
                                                >
                                                    {numToDecimal(
                                                        item.assigned
                                                    )}
                                                    /
                                                    {numToDecimal(
                                                        item.required
                                                    )}
                                                </span>
                                            </p>
                                            <p>
                                                <b>Current Stock: </b>{" "}
                                                {item.stock}
                                            </p>
                                            <p>
                                                <b>Available Stock: </b>{" "}
                                                {item.avaliable}
                                            </p>
                                            {editMode ? (
                                                <TextField
                                                    value={item.assigned}
                                                    label="Adjust Allocated"
                                                    disabled={
                                                        item.avaliable <= 0 &&
                                                        !(item.assigned > 0)
                                                    }
                                                    error={
                                                        item.avaliable <= 0 &&
                                                        !(item.assigned > 0)
                                                    }
                                                    helperText={
                                                        item.avaliable <= 0 &&
                                                            !(item.assigned > 0) ? "Not enough stock avaliable" : ''
                                                    }
                                                    onChange={(e) =>
                                                        adjustItem(
                                                            +e.target.value,
                                                            item,
                                                            row.itemId
                                                        )
                                                    }
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <hr />
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div hidden={value !== 1}>
                <HistoryTable
                    id={order.orderId}
                    location="charlie"
                    ref={undefined}
                />
            </div>
        </div>
    );
}
