/* eslint-disable no-restricted-globals */
import {
    Button,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../services/auth.service";
import { AddToToast } from "../../services/toast.service";
import { Loader } from "../common/loader/loader";
import { toastType } from "../common/toast/toast";
import "./suppliers.scss";
import { supplier } from "../../models/supplier";
import { SupplierRow } from "./supplierRow";
import { AddSupplier } from "./addSupplier";
import { EditSupplier } from "./editSupplier";
import { SearchBar } from "../common/search/search";

export function SupplierPage() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [search, setSearch] = useState("");
    const [suppliers, setSuppliers] = useState<supplier[]>([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState<supplier[]>([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selSupplier, setSelSupplier] = React.useState<supplier | null>(null);
    // const [newItem, setNewItem] = React.useState(false);
    const [dialogType, setDialogType] = React.useState<"newSupplier" | "editSupplier" | null>(null);
    const { state, setState } = useGlobalState();

    useEffect(() => {
        async function getSuppliers() {
            try {
                let suppliers: supplier[] = (await axios.get<supplier[]>("/api/suppliers/all"))
                    .data;
                suppliers.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                for (const supplier of suppliers) {
                    supplier.items?.sort((a, b) => {
                        if (a.sku < b.sku) return -1;
                        if (a.sku > b.sku) return 1;
                        return 0;
                    });
                }
                setIsLoaded(true);
                setSuppliers(suppliers);
                setFilteredSuppliers(suppliers);
            } catch (error) {
                setIsLoaded(true);
                setError(error);
                setState(
                    AddToToast(state, {
                        title: "Error",
                        description: "There was an error loading suppliers",
                        type: toastType.Error,
                    })
                );
                console.error(error);
            }
        }

        getSuppliers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.ws) return;

        const wsMessage = (msg: MessageEvent<any>) => {
            try {
                let info = JSON.parse(msg.data);
                if (info.supplierUpdate) updateSuppliers(info.supplierUpdate);
                if (info.supplierDeleted) supplierDeleted(info.supplierDeleted);
            } catch (error) { }
        }

        state.ws.addEventListener("message", wsMessage);

        return () => {
            if (state.ws) state.ws.removeEventListener("message", wsMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suppliers]);

    const handleOpen = (supplier: supplier, type: "editItem" | "newItem" | "adjustItem" = "editItem") => {
        setDialogType("editSupplier")
        setSelSupplier(supplier);
        setModalOpen(true);
    };

    const handleClose = (item?: supplier) => {
        if (item && !state.ws) {
            updateSuppliers(item);
        }
        setModalOpen(false);
        setDialogType(null);
    };

    const updateSuppliers = (supplier: supplier) => {
        let updatedIndex = suppliers.findIndex((x) => x.id === supplier.id);
        let newSuppliers = [...suppliers];
        if (updatedIndex > -1) {
            newSuppliers[updatedIndex] = supplier;
        } else {
            newSuppliers.push(supplier);
        }
        newSuppliers.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        setSuppliers(newSuppliers);
        filterSearch(search, newSuppliers);
    };

    const supplierDeleted = (supplierId: string) => {
        let newSuppliers = [...suppliers];
        newSuppliers = newSuppliers.filter(x => x.id !== parseInt(supplierId))
        setSuppliers(newSuppliers);
        filterSearch(search, newSuppliers);
    }

    const openNew = () => {
        setDialogType("newSupplier");
        setModalOpen(true);
    };

    const dialogContent = () => {
        let dialog;
        switch (dialogType) {
            case "newSupplier":
                dialog = <AddSupplier closeDialog={handleClose} />
                break;
            case "editSupplier":
                dialog = <EditSupplier supplier={selSupplier} closeDialog={handleClose} />
                break;
            default:
                break;
        }
        return dialog;
    }

    function filterSearch(searchVal: string, newSuppliers = suppliers) {
        setSearch(searchVal);
        searchVal = searchVal.toLowerCase();
        if (searchVal === "") {
            setFilteredSuppliers(newSuppliers);
            return;
        }
        setFilteredSuppliers(
            newSuppliers.filter(x => x.id.toString().toLowerCase().includes(searchVal)
                || x.name?.toLowerCase().includes(searchVal)
                || x.email.toLowerCase().includes(searchVal)
                || x.phone.toLowerCase().includes(searchVal)
                || x.items?.some(y => y.sku.toLowerCase().includes(searchVal))));
    }

    // table sorting
    const headCells: { id: keyof supplier; label: string }[] = [
        { id: "name", label: "Name" },
        { id: "note", label: "Note" },
        { id: "email", label: "Email" },
        { id: "phone", label: "Phone" },
    ];

    const [orderBy, setOrderBy] = React.useState<string>("sku");
    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("asc");

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof supplier
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property.toString());
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: string
    ): (
            a: { [key in Key]: number | string },
            b: { [key in Key]: number | string }
        ) => number {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy as Key)
            : (a, b) => -descendingComparator(a, b, (orderBy as Key));
    }

    function stableSort<T>(array: T[], comparator: (a: any, b: any) => number) {
        const stabilizedThis = array.map(
            (el, index) => [el, index] as [T, number]
        );
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    if (!isLoaded) {
        return (
            <div className="card">
                <Loader />
            </div>
        );
    } else if (error) {
        return <div className="card">Error: {error?.message}</div>;
    } else {
        return (
            <div className="card">
                <div className="itemHeader">
                    <h2>Suppliers</h2>
                    <div>
                        <Button color="primary" onClick={openNew} startIcon={<span className="material-icons">add</span>}>
                            Add Supplier
                        </Button>
                    </div>
                </div>
                <SearchBar filterFunction={filterSearch} />
                <br />
                <br />
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id.toString()}
                                        sortDirection={
                                            orderBy === headCell.id
                                                ? order
                                                : false
                                        }
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={
                                                orderBy === headCell.id
                                                    ? order
                                                    : "asc"
                                            }
                                            onClick={(event: any) =>
                                                handleRequestSort(
                                                    event,
                                                    headCell.id
                                                )
                                            }
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort<supplier>(
                                filteredSuppliers.map(x => {
                                    let tmp: Partial<supplier> = { ...x };
                                    tmp.modified = tmp.modified?.toString();
                                    return tmp as supplier;
                                }),
                                getComparator(order, orderBy)
                            ).map((row) => (
                                <SupplierRow
                                    key={row.id}
                                    row={row}
                                    openModal={handleOpen}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {dialogType !== null ? <Dialog
                    open={modalOpen}
                >
                    {dialogContent()}
                </Dialog> : ''}

            </div>
        );
    }
}
