import { useEffect, useState } from "react";
import { toastItem } from "./toast";
import "./toast.scss";

export function ToastItem(props: {toast: toastItem, removeItem: Function}) {

    const [isFadingOut, setIsFadingOut] = useState(false);

    function fadeOut(toastId: number) {
        setIsFadingOut(true);
        setTimeout(() => {
            handleRemoveItem(toastId)
        }, 200);
    };

    function handleRemoveItem(toastId: number) {
        setIsFadingOut(false);
        props.removeItem(toastId);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (props.toast.id) {
                fadeOut(props.toast.id)
            }
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    });

    return (
        <div
            key={props.toast.id}
            className={`notification toast bottom-right ${isFadingOut ? 'fadeout' : ''}`}
            style={{ backgroundColor: props.toast.backgroundColor }}
        >
            <span className="material-icons">{props.toast.icon}</span>
            <div>
                <p className="notification-title">{props.toast.title}</p>
                <p className="notification-message">{props.toast.description}</p>
            </div>
            <button onClick={() => fadeOut(props.toast.id)}>X</button>
        </div>
    );
}
